body
{
  font-family: 'Roboto', sans-serif;
}

$main-red: #CE3935;
$main-blue:#337AB7; 


ul.social li
{
  display: inline-block;
  margin-right: 20px;
}
ul.social li{
  width: 33px;
  height: 20px;
  background: $main-red;
  position: relative;
  text-align: center;
}
ul.social li::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 8px solid $main-red;
}
ul.social li::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 8px solid $main-red;
}
ul.social li a 
{
  color: #fff;
  position: relative;
  z-index: 99;
}
.top
{
  background-color: #fff;
  padding: 10px 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}
.top p
{
  margin-bottom: 0px;
}
.logo 
{
width: 120px;
}
.logo img
{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.box1
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 20px;
  min-height: 329px;
  display: flex;
  align-items: center;

  &:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }
}
.box1bis
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 20px;
  min-height: 329px;

  &:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }
}
.mt-168
{
  margin-top: -168px;
}
.mt--70
{
  margin-top: -70px;
}
.box7
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 20px;
  min-height: 274px;

  &:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }
}
.box8
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 20px;
  min-height: 526px;
  &:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  } 
}
.box8 h3{
  color: $main-blue;
}
.box9
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 20px;
  min-height: 329px;

  &:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  } 
}
.box9 h3
{
  color: $main-blue;
  padding: 10px 0;
}
.box10
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 20px;
  min-height: 471px;

  &:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  } 
}
.box10 h3
{
  color: $main-blue;
  padding: 10px 0;
}
.box11
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 33px;
  min-height: 281px;

  &:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
  } 
}
.box11 h3
{
  color: $main-blue;
  padding: 10px 0;
}
.link {
  text-decoration: none;
  color: black;
}
.link {
  text-decoration: none;
}
.icon i
{
  color: #fff;
}
.icon{
  width: 33px;
  height: 20px;
  background: $main-red;
  position: relative;
  text-align: center;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
}
.icon:hover {
  transform: rotate(360deg);
}
.icon::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 8px solid $main-red;
}
.icon::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 8px solid $main-red;
}
section.slider 
{
  position: relative;
  height: 600px;
}
section.slider video
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.caption
{
  text-align: center;
  position: absolute;
  left: 40px;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.caption h1
{
  font-family: Roboto;
  font-style: normal;
  font-size: 50px;
  line-height: 59px;
  color: #FFFFFF;
}
.caption h2
{
  font-family: Roboto;
  font-style: normal;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
}
section h2
{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
section h3
{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.box1 p
{
  text-align: justify;
}
.box7 p
{
  text-align: justify;
}
.button
{
  background-color: $main-red;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  border: unset;
  padding: 15px 20px;
  display:inline-block;
}
.pad10  
{
  padding: 10px 0px;
}
.pad20  
{
  padding: 20px 0;
}
.pad40  
{
  padding: 40px 0px;
}
.pos-top-1
{
  margin-top: -150px;
}
section.one
{
  padding: 70px 0px;
}
section.two
{
  background-image: url(../img/remorquage-autoroute.jpg);
  padding-top: 70px;
}
section.three
{
  padding: 30px 0px;
}
section.four
{
  padding: 30px 0px;
  margin-top: 60px;
}
.box-img1
{
  height: auto;
  margin-top: 60px;
}
.box-img1 img
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bg-bleu
{
  background-color: $main-blue;
  padding: 40px 40px 40px 0px;
  position: relative;
  left: -150px;
  width: 723px;
}
.bg-gray
{
  background: #F7F7F7;
}
.pos1
{
  position: relative;
  top: 40px;
  right: -100px;
  z-index: 99;
}
section p
{
  text-align: justify;
}
.calque1
{
  background-color: $main-blue;
  height: 500px;
  width: 100%;
  z-index: 0;
  position: absolute;
  right: -50px;
  top: -50px;
}
.calque2
{
  background: #FFFFFF;
  box-shadow: 8px -6px 26px rgba(0, 0, 0, 0.25);
  width: 345px;
  height: 400px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.box-img2
{
  height: 400px;
  position: relative;
}
.box-img2 img
{
  height: 450px;
  width: 475px;
  object-fit: cover;
  position: relative;
  z-index: 99;
  top: -25px;
}
div.pad30 
{
  padding: 30px 0px;
}
.box2
{
  background: #FFFFFF;
box-shadow: 0px 3px 36px rgba(0, 0, 0, 0.25);
padding: 20px;
position: relative;
}
.box5
{
  background: #FFFFFF;
box-shadow: 0px 3px 36px rgba(0, 0, 0, 0.25);
padding: 20px;
position: relative;
}
.border-right
{
  border-right: 1px solid #000000;
}
.body-head video {
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
  .body-head {
	height: 500px;
	position: relative;
  }
.pad10
{
  padding: 10px 0px;
}
.box2::before
{
  content: "";
  width: 50%;
  height: 125px;
  position: absolute;
  bottom: -20px;
  left: -20px;
  background-color: $main-blue;
  z-index: -1;
}
.box2::after
{
  content: "";
  width: 45%;
  height: 125px;
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: $main-blue;
  z-index: -1;
}
.box5::before
{
  content: "";
  width: 50%;
  height: 100px;
  position: absolute;
  bottom: -20px;
  left: -20px;
  background-color: $main-blue;
  z-index: -1;
}
.box5::after
{
  content: "";
  width: 45%;
  height: 100px;
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: $main-blue;
  z-index: -1;
}
.button1
{
  background-color: $main-blue;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  border: unset;
  padding: 15px 20px;
  transition: transform 250ms;
  @media (max-width: 768px){
    font-size: 18px;
    white-space: nowrap;
  }
}
.button1:hover
{
  transform: translateY(-10px);
}
.button1 a, .button1 a:hover, .button1 a:visited, .button1 a:active
{
  text-decoration: none; 
  color: white;
}
.button2
{
  background-color: $main-blue;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  border: unset;
  padding: 5px 100px;
  transition: transform 250ms;
}
.button2:hover
{
  transform: translateY(-10px);
}
.button2 a, .button2 a:hover, .button2 a:visited, .button2 a:active
{
  text-decoration: none; 
  color: white;
}
.box3
{
  background: #FFFFFF;
box-shadow: 8px -6px 26px rgba(0, 0, 0, 0.25);
padding: 30px;
}
.box-img3
{
height: 220px;
}
.box-img3:hover{
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
}
.box-img3 img
{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.div20  
{
  padding: 20px;
}
.bg-blue
{
  background-color: $main-blue;
  padding: 20px;
}
.bg-blue2
{
  background-color: $main-blue;
  padding: 20px;
}
.bg-blue h3
{
  color: #fff;
}
.bg-blue2 h3
{
  color: #fff;
}
.bg-blue p 
{
  color: #fff;
}
.bg-blue2 p 
{
  color: #fff;
}
.bg1
{
  background: #EEF2F4;
}
.box-cli
{
  height: 50px;
  @media (max-width: 768px){
    margin-bottom: 15px;
  }
}
.box-cli img
{
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
footer
{
  padding: 50px 0px;
}
footer h4
{
  font-weight: bold;
  font-size: 15px;
}
footer ul
{
  padding: 0px;
  margin: 0px;
}
footer li
{
  list-style: none;
}
.copyright
{
  padding: 20px 0px;
  background-color: $main-blue;
  text-align: center;
}
.copyright p 
{
margin: 0px;
color: #fff;
}
.title1 img
{
  height: 25px;
}
.slide
{
  text-align: center;
  padding: 70px 0px;
  position: relative;
  background-size: cover;
  background-position: center;
  padding-top: 200px;
}
.slide .container
{
  position: relative;
}
.slide1
{
  background-image: url(../img/slide1.jpg);
}
.slide::before
{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
.floating-number {
  background-color: #CE3935;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease;
  width: 208px;
  height: 55px;
  font-size: 25px;

  &:hover {
    background-color: #1b1c1e;
    color: white !important;
  }
  &:hover a {
    color: white !important;
    text-decoration: none !important;
  }
  a {
    color: white;
    font-weight: 600;
    font-size: 20px;
  }
  i {
    margin-right: 0.7rem;
    color: white;
  }
}

.slide h1
{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 59px;
  color: #FFFFFF;
}
.slide h2
{
  font-weight: bold;
  font-size: 25px;
  line-height: 35px;
  color: #FFFFFF;
}
.slide2
{
  background-image: url(../img/slide2.jpg);
}
.box4
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 10px;
}
.box6
{
  background: #FFFFFF;
  box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-top: 20px;
}
.scal img
{
  object-fit: scale-down;
}
.slide3
{
  background-image: url(../img/slide3.jpg);
}
section.contact .form-control
{
  background: #F1F1F1;
  border: unset;
}
.slide4
{
  background-image: url(../img/slide4.png);
}
.slide5
{
  background-image: url(../img/slide5.jpg);
}
.slide6
{
  background-image: url(../img/slide6.jpg);
}
.slide7
{
  background-image: url(../img/slide7.jpg);
}
.slide8
{
  background-image: url(../img/slide8.jpg);
}
.slide9
{
  background-image: url(../img/slide9.png);
}
.slide10
{
  background-image: url(../img/slide10.jpg);
}
.slide11
{
  background-image: url(../img/slide11.png);
}
.slide12
{
  background-image: url(../img/slide12.png);
}
.pp
{
  background-image: url(../img/pp.png);
}
.phone
{
  display: inline-block;
}
.phone p
{
  margin-bottom: 0px;
 
}
.phone p a
{
  color: #fff;
}
section.bg2
{
  background-image: url(../img/bg1.jpeg);
  background-size: cover;
  background-position: center;
  padding: 80px 0px;
  position: relative;
}
section.bg2 .container
{
  position: relative;
}
section.bg2::before
{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
section.bg2 p,section.bg2 a,section.bg2 h2,section.bg2 h3,section.bg2 li
{
  color: #fff;
}
.bloc4 h3
{
  color: $main-blue;
}
.bg-blue a
{
  color: $main-red;
}
.bg-blue2 a
{
  color: #337AB7!important;
}
.bg-blue .button
{
  color: #337AB7!important;
}
.bg-blue2 .button1
{
  background-color: #fff!important;
  padding: 10px 10px 10px 10px;
  font-size: 18px;
}
.img-responsive
{
  max-width: 100%;
}
#box3
{
  width: 900px;
  height: 604px;
  z-index: 3333;
  margin-left: -178px;
}
#box4Padding
{
  padding: 20px 20px 91px;
}
#box4Padding2
{
  padding: 20px 20px 66px;
}
#bg-blue
{
  margin-left: 137px;
  margin-top: -566px;
  padding: 30px 30px 111px 543px;
}
#imgResponse
{
  width: 530px;
  height: 330px;
  z-index: 230;
  padding-top: 410px;
  margin-left: -175px;
  img{
    width: 100%;
  }
}
#imgResponse2
{
  width: 213px;
  height: 330px;
  z-index: 230;
  margin-top: -77px;
  margin-left: 773px;
  img{
    width: 100%;
  }
}
#imgResponse2:hover
{
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.3); 
}
#townResponse
{
  font-size: 15px;
  padding-left: 5px;
}
#sectionBlack a {
  color: black;
}
#townResponse2
{
  font-size: 14px;
  text-align: center;
}
#townResponse3
{
  font-size: 14px;
  text-align: center;
  padding: 0 0 62px;
}
#townResponse4
{
  font-size: 14px;
  text-align: center;
  padding: 0 0 20px;
}
#townResponse5
{
  font-size: 14px;
  text-align: center;
  padding: 0 0 83px;
}
.num
{
  margin: 10px;
  color: $main-red;
  font-weight: 900;
}
.mt-40
{
  margin-top: 40px;
}
.mt--100
{
  margin-top: -100px;
}
.btn-blue
{
  background-color: $main-blue;
  padding: 5px 20px;
}
.btn-blue a
{
  font-size: 18px;
}
.btn-white
{
  background-color: #fff;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: $main-blue !important;
  border: unset;
  padding: 5px 20px;
  display:inline-block;
  font-weight: 900;
}
.icon1
{
  width: 60px;
  height: 60px;
  margin: -18px 22px 0 30px;
}
.icon2
{
  width: 90px;
  height: 60px;
  margin: 0 -10px 10px;
}
.fs20
{
  font-size: 20px;
}
.align-items-center
{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;}
#box9
{
  width: 515px;
  margin-left: -79px;
}
.div30 
{
  padding: 30px;
}
.backgr
{
  height: 600px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.backgr1
{
  background-image: url(../img/img6.png);
}
.backgr2
{
  background-image: url(../img/img8.png);
}
.backgr3
{
  background-image: url(../img/img10.png);
}
.backgr4
{
  background-image: url(../img/img12.png);
}
.backgr5
{
  background-image: url(../img/img14.png);
}
.backgr6
{
  background-image: url(../img/img16.png);
}
.backgr7
{
  background-image: url(../img/img18.png);
}
.backgr8
{
  background-image: url(../img/img21.png);
}
.backgr9
{
  background-image: url(../img/img23.png);
}
.posAbs1
{
  position: absolute;
  bottom: -100px;
}
.backgr1 .box3 ,.backgr1 .bg-blue 
{
  min-height: 364px;
}
.backgr2 .box3 ,.backgr2 .bg-blue 
{
  min-height: 270px;
}
.backgr3 .box3 ,.backgr3 .bg-blue 
{
  min-height: 300px;
}
.backgr4 .box3 ,.backgr4 .bg-blue 
{
  min-height: 300px;
}
.backgr5 .box3 ,.backgr5 .bg-blue 
{
  min-height: 316px;
}
.backgr6 .box3 ,.backgr6 .bg-blue 
{
  min-height: 316px;
}
.backgr9 .box3 ,.backgr9 .bg-blue 
{
  min-height: 365px;
}
.xlBorderLeft
{
  border-left: 60px solid  #337AB7;
}
.xlBorderRight
{
  border-right: 60px solid  #337AB7;
}
.xlBorderbottom
{
  border-bottom: 60px solid  #337AB7;
}
.minheight1
{
  min-height: 245px;
}
.coloredBlue
{
  color: $main-blue!important;
}
.scale-img 
{
  object-fit: scale-down!important;
}
div.number
{
  border: 2px solid #337AB7;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
  color: #337AB7;
  font-size: 35px;
  position: relative;
  top: -30px;
}
.white-txt h2
{
  color: #fff;
}
.white-txt li 
{
  color: #fff;
}
.height330
{
  height: 330px;
}
.box-icon
{
  height: 150px;
  margin-bottom: 20px;
}
.box-icon img 
{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
section.services h3
{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  color: #337AB7;
}
.icon001
{
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  padding: 15px;
}
.icon001 img 
{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}







@media (max-width:991px)
{
  #box9 {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width:315px)
{
  .caption h2 {
    font-size: 15px;
    line-height: 15px;
  }
}


@media (min-width:167px) and (max-width:575px)
{
  .mt23-ml40 h3 {
    margin-top: -23px !important;
    margin-left: 40px !important;
  }
  b {
    display: none !important;
  }
}

@media (max-width:1053px)
{
  #box3 {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  #bg-blue {
    margin-left: 0;
    margin-top: 0;
    padding: 30px;
  }
  #bg-bleu {
    margin-left: 0;
    margin-top: 0;
    left: 0;
    width: 100%;
  }
  #imgResponse {
    width: 100%;
    height: 100%;
    margin-left: 0;
    padding: 0 0 70px;
  }
  #imgResponse2 {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    padding: 50px 0 70px;
  }
}

@media (max-width:768px)
{
  .caption {
    top: 10%;
    transform: translateY(-10%);
    margin-top: 145px;
  }
  .caption h1 {
    font-size: 30px;
    line-height: 29px;
  }
  .pos1
  {
    right: 0px;
  }
  .calque1
  {
    right: 0px;
  }
  .calque2
  {
    right: 0px;
  }
  #pad20
  {
    padding: 20px;
  }
}

.img-box1{
  img{
    width: 100%
  }
}
.essonne-bg 
{
background-image: url(../img/essone-bg1.png);
background-size: cover;
background-position: center;
position: relative;
z-index: 0;
}
.essonne-bg::before
{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}
.white-txt p 
{
  color: #fff;
}
.essonne-bg h2
{
  padding-bottom: 30px;
  text-align: center;
}
.top--9
{
  top: -9;
}
.essonne-bg
{
  padding: 120px 0px;
}
.pad15
{
  padding: 15px 0px;
}
.bg-bleu3
{
  background-image: url(../img/bgblue3.png);
  background-color: unset;
}
.noBackground::before
{
  display: none;
}
.noBackground::after
{
  display: none;
}
.icon1.center
{
  
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

.redicon{
  width: 100% !important;
}

.color-white{
  color: #fff !important;
}

// Imports
@import "bootstrap";


.dropdown.active {
  a.dropdown-item{
    color: $body-color!important;
    &.active{
      color: $white!important;
    }
  }
}